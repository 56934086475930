import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getRequest } from '../UrlRequest';
import DataChart from './DataChart';

const Pulse = ({ patientId, selectedDate, selectedPeriod }) => {
  const [pulseData, setPulseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const location = useLocation();
  const application = location.state?.application || 'hearton';

  useEffect(() => {
    const fetchPulseData = async () => {
      if (!patientId || !selectedDate) return;

      setLoading(true);
      setError(null);
      setPulseData(null); // 날짜 변경 시 기존 데이터 초기화

      
try {
  const token = localStorage.getItem('accessToken');

  // 선택한 기간의 평균 맥박 데이터를 먼저 가져옴
  const periodResponse = await getRequest(
    application,
    `/doctor/${patientId}/pulse/period?on-date=${selectedDate}&period=${selectedPeriod}`,
    token
  );

  // 선택한 날짜의 맥박 데이터 가져옴
  const dailyResponse = await getRequest(
    application,
    `/doctor/${patientId}/pulse/daily?on-date=${selectedDate}`,
    token
  );

  // periodResponse가 있는 경우 항상 데이터를 설정
  if (periodResponse && periodResponse.heart_rates?.length > 0) {
    setPulseData({
      // dailyResponse가 있으면 해당 값을, 없으면 periodResponse의 마지막 값을 사용
      pulse_rate: dailyResponse?.heart_rate ?? periodResponse.heart_rates[periodResponse.heart_rates.length - 1],
      heart_rates: {
        dates: periodResponse.dates || [],
        values: periodResponse.heart_rates || [],
        is_dummy: periodResponse.is_dummy || []
      }
    });
  } else {
    setError('해당 기간에 맥박 데이터가 없습니다.');
  }
} catch (error) {
  setError('맥박 데이터를 불러오는 중 오류가 발생했습니다.');
} finally {
  setLoading(false);
}
    };

    fetchPulseData();
  }, [patientId, selectedDate, selectedPeriod, application]);

  if (loading) return <p><strong>로딩 중…</strong></p>;

  return pulseData ? (
    <div className="chartcontainer">
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <h3>맥박</h3>
        <span style={{ fontSize: '14px', color: '#666' }}>
          현재: <strong style={{ color: '#2196f3' }}>{pulseData.pulse_rate} bpm</strong>
        </span>
      </div>
      <DataChart
        patientId={patientId}
        dataType="pulse"
        endpoint={`/doctor/${patientId}/pulse/period`}
        label="평균 맥박"
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod}
        chartLabels={pulseData?.heart_rates?.dates}
        chartData={pulseData?.heart_rates?.values}
        chartDummy={pulseData?.heart_rates?.is_dummy}
      />
      {error && <p>{error}</p>}
    </div>
  ) : (
    <p style={{margin: '0px'}}>{selectedDate}에 대한 맥박 데이터가 없습니다.</p>
  );
};

export default Pulse;
