import React, { useState } from 'react';
import Diet from './Diet';
import Condition from './Condition';
import DeviceStatusForm from '../DeviceStatusForm';

const Tabs = ({ patientId, selectedDate, deviceStatusId }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isListView, setIsListView] = useState(true);

  // 탭 클릭 시 화면 전환을 함께 처리
  const handleTabClick = (index) => {
    setActiveTab(index);
    if (index === 2) { // 기기 상태 탭을 클릭했을 때
      setIsListView(!isListView); // 뷰 전환
    }
  };

  const tabs = [
    { label: '건강 상태', component: <Condition patientId={patientId} selectedDate={selectedDate} /> },
    { 
      label: '기기 상태 작성 폼', component: <DeviceStatusForm patientId={patientId} deviceStatusId={deviceStatusId} /> 
      // 뷰에 따른 탭 라벨
    },
    { label: '식단', component: <Diet patientId={patientId} selectedDate={selectedDate} /> },

  ];

  return (
    <div className="tab-container" style={{ 
      height: '100%', 
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden'
    }}>
      <div className="tab-buttons">
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => handleTabClick(index)}
            className={activeTab === index ? 'active-tab' : ''}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="tab-content" style={{ 
        flex: 1,
        //overflow: 'auto',
        padding: '10px'
      }}>
        {tabs[activeTab].component}
      </div>
    </div>
  );
};

export default Tabs;
