import React, { useState, useEffect } from 'react';
import { getRequest } from '../UrlRequest';
import {
    IconButton,
    Badge,
    Popover,
    List,
    ListItem,
    ListItemText,
    Snackbar,
    Alert,
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    Typography,
    Divider,
    Box,
    Checkbox,
    FormControlLabel,
    TextField,
    DialogActions,
    FormGroup,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
  } from '@mui/material';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AddIcon from '@mui/icons-material/Add'; // 추가
import SendIcon from '@mui/icons-material/Send';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

const Push = ({ application }) => {
  // 팝오버 상태 관리
  const [anchorEl, setAnchorEl] = useState(null);
  // 전체 알림 다이얼로그 상태 관리
  const [dialogOpen, setDialogOpen] = useState(false);
  // 알림 전송 관련 상태
  const [sendDialogOpen, setSendDialogOpen] = useState(false);
  const [patients, setPatients] = useState([]);
  const [selectedPatients, setSelectedPatients] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  // 알림 전송 관련 상태에 제목 추가
  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationMessage, setNotificationMessage] = useState('');
  const [notificationType, setNotificationType] = useState('info');

  // 환자 목록 불러오기
  useEffect(() => {
    const fetchPatients = async () => {
      try {
        const token = localStorage.getItem('accessToken');
        const data = await getRequest(application, 'doctor/patients', token);
        setPatients(data.patients.filter(patient => patient.name !== 'chatbot'));
      } catch (error) {
        console.error('Error fetching patients:', error);
        showToast('환자 목록을 불러오는데 실패했습니다.', 'error');
      }
    };

    fetchPatients();
  }, [application]);

  // 알림 전송 다이얼로그 열기 시 초기화
  const handleSendDialogOpen = () => {
    setSendDialogOpen(true);
    setSelectedPatients([]);
    setSelectAll(false);
    setNotificationTitle('');
    setNotificationMessage('');
  };

  // 알림 전송 다이얼로그 닫기
  const handleSendDialogClose = () => {
    setSendDialogOpen(false);
  };

  // 전체 선택 처리
  const handleSelectAll = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedPatients(patients.map(patient => patient.id));
    } else {
      setSelectedPatients([]);
    }
  };

  // 개별 환자 선택 처리
  const handlePatientSelect = (patientId) => {
    setSelectedPatients(prev => {
      if (prev.includes(patientId)) {
        return prev.filter(id => id !== patientId);
      } else {
        return [...prev, patientId];
      }
    });
  };

// 알림 전송 처리 수정
const handleSendNotification = () => {
  if (!notificationTitle.trim()) {
    showToast('알림 제목을 입력해주세요.', 'warning');
    return;
  }
  if (!notificationMessage.trim()) {
    showToast('알림 내용을 입력해주세요.', 'warning');
    return;
  }
  if (selectedPatients.length === 0) {
    showToast('알림을 받을 환자를 선택해주세요.', 'warning');
    return;
  }

  const newNotification = {
    id: Date.now(),
    title: notificationTitle,
    content: notificationMessage,
    time: '방금 전',
    isRead: false,
    type: notificationType,
    recipients: selectedPatients
  };

  setAllNotifications(prev => [newNotification, ...prev]);
  setNotifications(prev => [newNotification, ...prev].slice(0, 5));
  
  showToast(`${selectedPatients.length}명의 환자에게 알림을 전송했습니다.`, 'success');
  handleSendDialogClose();
};

  // 임의의 메시지 목록
  const sampleMessages = [
    '새로운 업데이트가 있습니다.',
    '시스템 점검 안내',
    '새로운 메시지가 도착했습니다.',
    '일정 알림이 있습니다.',
    '긴급 공지사항이 있습니다.',
    '새로운 기능이 추가되었습니다.'
  ];
  // 새로운 알림 추가 함수
  // 새로운 알림 추가 함수 수정
  const handleAddNotification = () => {
    const randomMessage = sampleMessages[Math.floor(Math.random() * sampleMessages.length)];
    
    const newNotification = {
      id: Date.now(),
      message: randomMessage,
      time: '방금 전',
      isRead: false
    };

    // 최근 알림 목록 업데이트 (최대 5개 유지)
    setNotifications(prev => [newNotification, ...prev].slice(0, 5));
    
    // 전체 알림 목록 업데이트 (제한 없음)
    setAllNotifications(prev => [newNotification, ...prev]);

    showToast('새로운 알림이 추가되었습니다.', 'info');
  };



  // 토스트 알림 상태 관리
  const [toast, setToast] = useState({
    open: false,
    message: '',
    severity: 'info'
  });
  
  // 알림 데이터 상태 관리
  const [notifications, setNotifications] = useState([
    { 
      id: 1, 
      title: '새로운 알림',
      content: '새로운 알림의 상세 내용입니다.',
      time: '방금 전', 
      isRead: false 
    },
    { 
      id: 2, 
      title: '시스템 업데이트 예정',
      content: '시스템 점검이 예정되어 있습니다. 자세한 내용은 본문을 확인해주세요.',
      time: '1시간 전', 
      isRead: false 
    },
  ]);


  const [allNotifications, setAllNotifications] = useState([
    { id: 1, message: '새로운 알림이 있습니다.', time: '방금 전', isRead: false },
    { id: 2, message: '시스템 업데이트 예정', time: '1시간 전', isRead: false },
    { id: 3, message: '일정 알림: 회의', time: '3시간 전', isRead: true },
    { id: 4, message: '새로운 메시지가 도착했습니다', time: '1일 전', isRead: true },
    { id: 5, message: '시스템 점검 완료', time: '2일 전', isRead: true },
  ]);

  // 선택된 알림 상태 추가
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);

  // 알림 상세 내용 보기
  const handleNotificationClick = (notification) => {
    setSelectedNotification(notification);
    setDetailDialogOpen(true);
  };


  // 알림 읽음 처리 함수
  const handleReadNotification = (notificationId) => {
    // 전체 알림 목록 업데이트
    setAllNotifications(prev => prev.map(notification => 
      notification.id === notificationId 
        ? { ...notification, isRead: true }
        : notification
    ));

    // 최근 알림 목록 업데이트
    setNotifications(prev => prev.map(notification =>
      notification.id === notificationId
        ? { ...notification, isRead: true }
        : notification
    ));

    // 토스트 메시지 표시
    showToast('알림을 읽음 처리했습니다.', 'success');
  };
    
      // 모든 알림 읽음 처리 함수
      const handleReadAllNotifications = () => {
        // 전체 알림 목록 업데이트
        setAllNotifications(prev => prev.map(notification => ({
          ...notification,
          isRead: true
        })));
    
        // 최근 알림 목록 업데이트
        setNotifications(prev => prev.map(notification => ({
          ...notification,
          isRead: true
        })));
    
        // 토스트 메시지 표시
        showToast('모든 알림을 읽음 처리했습니다.', 'success');
      };
    
  // 읽지 않은 알림 개수 계산
  const unreadCount = allNotifications.filter(notification => !notification.isRead).length;

  // 팝오버 열기
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // 팝오버 닫기
  const handleClose = () => {
    setAnchorEl(null);
  };

  // 다이얼로그 열기
  const handleDialogOpen = () => {
    setDialogOpen(true);
    handleClose(); // 팝오버 닫기
  };

  // 다이얼로그 닫기
  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  // 토스트 알림 표시
  const showToast = (message, severity = 'info') => {
    setToast({
      open: true,
      message,
      severity
    });
  };

  // 토스트 알림 닫기
  const handleToastClose = () => {
    setToast({
      ...toast,
      open: false
    });
  };

  const open = Boolean(anchorEl);
  const id = open ? 'notification-popover' : undefined;

  return (
    <>
          <Box sx={{ display: 'flex', gap: 1 }}>
        <IconButton
          aria-describedby={id}
          onClick={handleClick}
          color="inherit"
        >
          <Badge badgeContent={unreadCount} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        

      </Box>
              {/* 알림 전송 버튼 추가 */}
      <IconButton
        onClick={handleSendDialogOpen}
        color="primary"
        size="small"
      >
        <SendIcon />
      </IconButton>

      {/* 알림 전송 다이얼로그 수정 */}
      <Dialog
        open={sendDialogOpen}
        onClose={handleSendDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>알림 전송</DialogTitle>
        <DialogContent>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>알림 유형</InputLabel>
            <Select
              value={notificationType}
              onChange={(e) => setNotificationType(e.target.value)}
            >
              <MenuItem value="info">일반 알림</MenuItem>
              <MenuItem value="warning">주의 알림</MenuItem>
              <MenuItem value="error">긴급 알림</MenuItem>
            </Select>
          </FormControl>
          
          {/* 제목 입력 필드 추가 */}
          <TextField
            fullWidth
            variant="outlined"
            label="알림 제목"
            value={notificationTitle}
            onChange={(e) => setNotificationTitle(e.target.value)}
            sx={{ mb: 2 }}
          />
          
          <TextField
            fullWidth
            multiline
            rows={3}
            variant="outlined"
            label="알림 내용"
            value={notificationMessage}
            onChange={(e) => setNotificationMessage(e.target.value)}
            sx={{ mb: 2 }}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleSelectAll}
              />
            }
            label="전체 선택"
          />

          <FormGroup sx={{ maxHeight: 200, overflow: 'auto' }}>
            {patients.map((patient) => (
              <FormControlLabel
                key={patient.id}
                control={
                  <Checkbox
                    checked={selectedPatients.includes(patient.id)}
                    onChange={() => handlePatientSelect(patient.id)}
                  />
                }
                label={`${patient.name} (${patient.research_no})`}
              />
            ))}
          </FormGroup>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSendDialogClose}>취소</Button>
          <Button 
            onClick={handleSendNotification} 
            variant="contained" 
            color="primary"
            disabled={!notificationTitle.trim() || !notificationMessage.trim() || selectedPatients.length === 0}
          >
            전송
          </Button>
        </DialogActions>
      </Dialog>
      {/* 전체 알림 다이얼로그 수정 */}
      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6">
              전체 알림
            </Typography>
            <Button 
              onClick={handleReadAllNotifications}
              color="primary"
              disabled={!allNotifications.some(notification => !notification.isRead)}
            >
              전체 읽음
            </Button>
          </Box>
        </DialogTitle>
        <DialogContent>
          <List>
            {allNotifications.map((notification) => (
              <React.Fragment key={notification.id}>
                <ListItem
                  sx={{
                    backgroundColor: notification.isRead ? 'inherit' : '#f5f5f5',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eaeaea',
                    }
                  }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                          component="div"
                          variant="body1"
                          sx={{ 
                            fontWeight: notification.isRead ? 'normal' : 'bold',
                            flex: 1
                          }}
                        >
                          {notification.title}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={(e) => handleReadNotification(e, notification.id)}
                          sx={{ ml: 1 }}
                        >
                          <MarkEmailReadIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                    secondary={notification.time}
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))}
          </List>
        </DialogContent>
      </Dialog>
      {/* 팝오버 수정 */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List sx={{ width: 300 }}>
          {notifications.length > 0 ? (
            <>
              {notifications.map((notification) => (
                <ListItem 
                  key={notification.id}
                  sx={{
                    backgroundColor: notification.isRead ? 'inherit' : '#f5f5f5',
                    cursor: 'pointer',
                    '&:hover': {
                      backgroundColor: '#eaeaea',
                    }
                  }}
                  onClick={() => handleNotificationClick(notification)}
                >
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography
                          component="div"
                          variant="body1"
                          sx={{ 
                            fontWeight: notification.isRead ? 'normal' : 'bold',
                            flex: 1
                          }}
                        >
                          {notification.title}
                        </Typography>
                        <IconButton
                          size="small"
                          onClick={(e) => handleReadNotification(e, notification.id)}
                          sx={{ ml: 1 }}
                        >
                          <MarkEmailReadIcon fontSize="small" />
                        </IconButton>
                      </Box>
                    }
                    secondary={notification.time}
                  />
                </ListItem>
              ))}
            </>
          ) : (
            <ListItem>
              <Typography variant="body2" color="textSecondary" sx={{ width: '100%', textAlign: 'center' }}>
                새로운 알림이 없습니다
              </Typography>
            </ListItem>
          )}
          <Divider />
          <ListItem>
            <Button 
              fullWidth 
              onClick={handleDialogOpen}
              sx={{ mt: 1 }}
            >
              전체 알림 보기 ({allNotifications.length}개)
            </Button>
          </ListItem>
        </List>
      </Popover>

      {/* 알림 상세 내용 다이얼로그 */}
      <Dialog
        open={detailDialogOpen}
        onClose={() => setDetailDialogOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        {selectedNotification && (
          <>
            <DialogTitle>
              <Typography variant="h6">
                {selectedNotification.title}
              </Typography>
              <Typography variant="caption" color="textSecondary">
                {selectedNotification.time}
              </Typography>
            </DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                {selectedNotification.content}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={() => setDetailDialogOpen(false)}
                color="primary"
              >
                닫기
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>

      {/* 전체 알림 토스트 */}
      <Snackbar
        open={toast.open}
        autoHideDuration={6000}
        onClose={handleToastClose}
        anchorOrigin={{ 
          vertical: 'top', 
          horizontal: 'center'  // 'right'가 아닌 'center'로 수정
        }}
      >
        <Alert onClose={handleToastClose} severity={toast.severity}>
          {toast.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Push;