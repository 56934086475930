import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getRequest, postRequest, deleteRequest, putRequest } from './UrlRequest';
import axios from 'axios';
import './Sidebar.css';
import { handleTokenExpiry } from './Refresh';

const Sidebar = ({ setSelectedPatientId, setSelectedPatientInfo }) => {
  const [userName, setUserName] = useState('');
  const [patients, setPatients] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSearching, setIsSearching] = useState(false);
  const [colorFilter, setColorFilter] = useState('all');
  const [activeBookmarkId, setActiveBookmarkId] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [size] = useState(30);

  const navigate = useNavigate();
  const location = useLocation();
  const application = location.state?.application || 'hearton';

  useEffect(() => {
    const fetchUserName = async () => {
      const token = localStorage.getItem('accessToken');
      if (!token) return;

      try {
        const data = await getRequest(application, '/doctor', token);
        setUserName(data.nickname || '사용자');
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserName();
  }, [application]);

  const handleLogout = async () => {
    try {
      const token = localStorage.getItem('accessToken');
      await axios.post(
        'https://hearton.livincare.kr/api/v1/logout',
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      console.log('Logged out successfully.');
    } catch (error) {
      console.error('Error logging out:', error);
    } finally {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      navigate('/login');
    }
  };

  const fetchPatientsList = useCallback(async (pageNum = 1, append = false) => {
    try {
      setLoading(true);
      const token = localStorage.getItem('accessToken');
      const url = `doctor/patients?page=${pageNum}&size=${size}&option=${colorFilter}`;
      const data = await getRequest(application, url, token);
      
      const patientsWithBookmark = data.patients.map((patient) => ({
        ...patient,
        bookmarkColor: patient.bookmark_color || 'void',
      }));
      
      setHasMore(data.patients.length === size);
      
      if (append) {
        setPatients(prev => [...prev, ...patientsWithBookmark]);
      } else {
        setPatients(patientsWithBookmark);
      }
    } catch (error) {
      console.error('Error fetching patients:', error);
      setError('환자 데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [application, size]);

  // 검색 함수 업데이트
  const searchPatients = async () => {
    if (!searchTerm) {
      setIsSearching(false);
      setPage(1);
      fetchPatientsList(1, false);
      return;
    }

    try {
      setLoading(true);
      setIsSearching(true);
      const token = localStorage.getItem('accessToken');
      const searchUrl = `doctor/patients/search?name=${searchTerm}&page=1&size=${size}&option=${colorFilter}`;
      const data = await getRequest(application, searchUrl, token);
      
      const patientsWithBookmark = data.patients.map((patient) => ({
        ...patient,
        bookmarkColor: patient.bookmark_color || 'void',
      }));
      
      setPatients(patientsWithBookmark);
      setHasMore(data.patients.length === size);
    } catch (error) {
      console.error('Error searching patients:', error);
      setError('검색 결과를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  const handlePatientClick = (patient) => {
    setSelectedPatientId(patient.id);
    setSelectedPatientInfo(patient);
  };

  const handleBookmarkClick = (patientId, event) => {
    event.stopPropagation();
    setActiveBookmarkId(activeBookmarkId === patientId ? null : patientId);
  };

  const handleColorSelect = async (patientId, color, event) => {
    event.stopPropagation();
    setActiveBookmarkId(null);

    try {
      const token = localStorage.getItem('accessToken');
      const data = JSON.stringify({
        patient_id: patientId,
        color: color
      });

      await putRequest(
        application,
        `/doctor/patient/bookmark`,
        data,
        token
      );
      
      // Update the local state
      setPatients(prevPatients =>
        prevPatients.map(patient =>
          patient.id === patientId
            ? { ...patient, bookmarkColor: color }
            : patient
        )
      );
    } catch (error) {
      console.error('Error updating bookmark:', error);
    }
  };

  const BookmarkIcon = () => (
    <svg 
      xmlns="http://www.w3.org/2000/svg" 
      viewBox="0 0 24 24" 
      width="24" 
      height="24" 
      fill="currentColor"
      stroke="currentColor" 
      strokeWidth="2"
    >
      <path d="M19 21l-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z"/>
    </svg>
  );

  const BookmarkColorMenu = ({ patientId, currentColor }) => {
    const colors = ['void', 'green', 'blue', 'red'];
    
    return (
      <div 
        className="bookmark-color-menu"
        onClick={(e) => e.stopPropagation()}
      >
        {colors.map((color) => (
          <button
            key={color}
            className={`color-option ${color} ${currentColor === color ? 'active' : ''}`}
            onClick={(e) => handleColorSelect(patientId, color, e)}
          >
            <BookmarkIcon />
          </button>
        ))}
      </div>
    );
  };

  const getFilteredPatients = (patientsList) => {
    if (colorFilter === 'all') return patientsList;
    return patientsList.filter(patient => patient.bookmarkColor === colorFilter);
  };

  const handleFilterClick = (color) => {
    setPage(1);
    setColorFilter(color);
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem('accessToken');
        const url = `doctor/patients?page=1&size=${size}&option=${color}`;
        const data = await getRequest(application, url, token);
        
        const patientsWithBookmark = data.patients.map((patient) => ({
          ...patient,
          bookmarkColor: patient.bookmark_color || 'void',
        }));
        
        setHasMore(data.patients.length === size);
        setPatients(patientsWithBookmark);
      } catch (error) {
        console.error('Error fetching patients:', error);
        setError('환자 데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  const loadNextPage = useCallback(() => {
    if (!loading && hasMore) {
      const nextPage = page + 1;
      setPage(nextPage);
      fetchPatientsList(nextPage, true);
    }
  }, [loading, hasMore, page, fetchPatientsList]);

  const handleScroll = useCallback((e) => {
    const { scrollTop, clientHeight, scrollHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      loadNextPage();
    }
  }, [loadNextPage]);

  useEffect(() => {
    fetchPatientsList();
  }, [fetchPatientsList]);

  return (
    <div className="null" style={{height: '100%'}}>
      <div className="top-bar">
        <div className="top-bar-content">
          <p>{userName}님</p>
          <button className="logout-button" onClick={handleLogout}
                    style={{
                              padding: '6px 12px',
                              marginRight: '12px',
                              backgroundColor: '#4299e1',
                              color: 'white',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              fontSize: '14px',
                              fontWeight: '500',
                              transition: 'background-color 0.2s ease',
                              ':hover': {
                                backgroundColor: '#3182ce'
                              }
                            }}>
            로그아웃
          </button>
        </div>
      </div>

      <div className="search-bar">
        <input
          type="text"
          placeholder="환자 이름 검색"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button onClick={searchPatients} className="search-button">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" style={{ width: '24px', height: '24px' }}>
            <circle cx="11" cy="11" r="8" />
            <line x1="21" y1="21" x2="16.65" y2="16.65" />
          </svg>
        </button>
      </div>
      <div className="filter-buttons" style={{padding: '10px'}}>
        <button
          className={`filter-button all ${colorFilter === 'all' ? 'active' : ''}`}
          onClick={() => handleFilterClick('all')}
        >
          ALL
        </button>
        {['red', 'blue', 'green', 'void'].map(color => (
          <button
            key={color}
            className={`filter-button ${color} ${colorFilter === color ? 'active' : ''}`}
            onClick={() => handleFilterClick(color)}
          />
        ))}
      </div>
      <div 
        className="patient-list" 
        onScroll={handleScroll}
        style={{
          height: 'calc(100vh - 180px)', // 상단 바, 검색 바, 필터 버튼 높이를 제외한 나머지 공간
          overflowY: 'auto',
          overflowX: 'hidden'
        }}
      >
        {loading && page === 1 ? (
          <p>로딩 중...</p>
        ) : (
          <ul style={{ 
            margin: 0, 
            padding: 0,
            listStyle: 'none'
          }}>
            {getFilteredPatients(patients).map((patient) =>
              patient.name !== 'chatbot' && (
                <div 
                  key={patient.id}
                  className="patient-item" 
                  onClick={() => handlePatientClick(patient)}
                >
                  <div className="patient-info-container">
                    <div className="patient-info-row">
                      <div className="patient-basic-info">
                        <p>
                          <strong>{patient.name}</strong> ({patient.age}세)
                        </p>
                      </div>
                    </div>
                    <div className="patient-info-row">
                      <p className="patient-account"> {/* 연구번호인데요 */}
                        연구 번호:{patient.research_no}
                      </p>
                    </div>
                  </div>
                  <div className="bookmark-section">
                    <div className="bookmark-container">
                      <button
                        onClick={(e) => handleBookmarkClick(patient.id, e)}
                        className={`bookmark-button ${patient.bookmarkColor}`}
                      >
                        <BookmarkIcon />
                      </button>
                      {activeBookmarkId === patient.id && (
                        <BookmarkColorMenu
                          patientId={patient.id}
                          currentColor={patient.bookmarkColor}
                        />
                      )}
                    </div>
                  </div>
                </div>
              )
            )}
            {loading && page > 1 && (
              <div style={{ textAlign: 'center', padding: '10px' }}>
                추가 데이터 로딩 중...
              </div>
            )}
            {!loading && hasMore && (
              <div style={{ 
                textAlign: 'center', 
                padding: '10px',
                borderTop: '1px solid #eee'
              }}>
                <button 
                  onClick={loadNextPage}
                  style={{
                    padding: '8px 16px',
                    backgroundColor: '#f8f9fa',
                    border: '1px solid #dee2e6',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    color: '#495057',
                    fontSize: '14px',
                    transition: 'all 0.2s ease',
                  }}
                  onMouseOver={(e) => e.target.style.backgroundColor = '#e9ecef'}
                  onMouseOut={(e) => e.target.style.backgroundColor = '#f8f9fa'}
                >
                  더 보기
                </button>
              </div>
            )}
          </ul>
        )}
      </div>
    </div>
  );
};

export default Sidebar;