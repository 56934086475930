import React, { useState } from 'react';
import BloodPressure from './rightpannel/BloodPressure';
import Weight from './rightpannel/Weight';
import Pulse from './rightpannel/Pulse';
import ChatList from './rightpannel/ChatList';
import Push from './rightpannel/Push';
import './SideTab.css';

const SideTab = ({ 
  patientId, 
  selectedDate, 
  selectedPeriod, 
  patient,
  onVisibilityChange,
  showCharts 
}) => {
  const [activeTab, setActiveTab] = useState('stats');
  const [chatListKey, setChatListKey] = useState(0);

  const handleTabChange = (tab) => {
    setActiveTab(tab);
    if (tab === 'chat') {
      setChatListKey(prev => prev + 1);
    }
  };

  // 환자 데이터 클릭 시 호출될 함수
  const handleDataClick = (date) => {
    // setShowCharts(true);
  };

  const renderContent = () => {
    switch (activeTab) {
      case 'stats':
        return (
          <div className="stats-container">
            {!patientId ? (
              <div className="loading-message">
                <p>환자를 선택하시면 차트가 표시됩니다.</p>
              </div>
            ) : !showCharts ? (
              <div className="loading-message">
                <p>환자의 데이터를 클릭하시면 차트가 표시됩니다.</p>
              </div>
            ) : (
              <>
                <BloodPressure patientId={patientId} selectedDate={selectedDate} selectedPeriod={selectedPeriod} />
                <Weight patientId={patientId} selectedDate={selectedDate} selectedPeriod={selectedPeriod} />
                <Pulse patientId={patientId} selectedDate={selectedDate} selectedPeriod={selectedPeriod} />
              </>
            )}
          </div>
        );
      case 'notification':
        return (
          <div className="notification-container">
            <Push onClose={() => {}} />
          </div>
        );
      case 'chat':
        return (
          <div className="chatlist-container">
            <ChatList 
              key={chatListKey}
              patientId={patientId} 
              patient={patient}
              onVisibilityChange={onVisibilityChange}
              isVisible={true}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="side-tab">
      <div className="tab-buttons">
        <button
          className={`tab-button ${activeTab === 'stats' ? 'active' : ''}`}
          onClick={() => handleTabChange('stats')}
        >
          차트
        </button>
        <button
          className={`tab-button ${activeTab === 'notification' ? 'active' : ''}`}
          onClick={() => handleTabChange('notification')}
        >
          (미구현)
        </button>
        <button
          className={`tab-button ${activeTab === 'chat' ? 'active' : ''}`}
          onClick={() => handleTabChange('chat')}
        >
          챗봇이력
        </button>
      </div>
      <div className="tab-content">
        {renderContent()}
      </div>
    </div>
  );
};

export default SideTab;
