import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-modal';
import { postRequest, ssoRequest } from './UrlRequest';
import './Modal.css';

const SignupModal = ({ isOpen, onRequestClose, userInfo = null }) => {
  const [step, setStep] = useState(1);
  const [account, setAccount] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [nickname, setNickname] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [application] = useState('hearton');

  const [threeMinuteTimer, setThreeMinuteTimer] = useState(0);
  const [tenMinuteTimer, setTenMinuteTimer] = useState(0);
  const [isTenMinuteLimit, setIsTenMinuteLimit] = useState(false);

  const threeMinuteTimerRef = useRef(null);
  const tenMinuteTimerRef = useRef(null);

  useEffect(() => {
    // userInfo가 null이 아닐 때만 값을 설정
    if (userInfo) {
      setAccount(userInfo.account || '');
      setPassword(userInfo.password || '');
      setConfirmPassword(userInfo.password || '');
      setNickname(userInfo.nickname || '');
      // userInfo가 있을 경우 step 2로 바로 이동
      if (userInfo.account) {
        setStep(2);
      }
    }
  }, [userInfo]);


  const nextStep = () => setStep((prevStep) => prevStep + 1);
  const prevStep = () => setStep((prevStep) => prevStep - 1);

  const handlePhoneChange = (e) => {
    let input = e.target.value.replace(/\D/g, '');
    if (input.length <= 3) {
      setPhone(input);
    } else if (input.length <= 7) {
      setPhone(`${input.slice(0, 3)}-${input.slice(3)}`);
    } else if (input.length <= 11) {
      setPhone(`${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7)}`);
    } else {
      setPhone(`${input.slice(0, 3)}-${input.slice(3, 7)}-${input.slice(7, 11)}`);
    }
  };

  const handleBirthdateChange = (e) => {
    const input = e.target.value.replace(/\D/g, '');
    if (input.length <= 4) {
      setBirthdate(input);
    } else if (input.length <= 6) {
      setBirthdate(`${input.slice(0, 4)}-${input.slice(4)}`);
    } else {
      setBirthdate(`${input.slice(0, 4)}-${input.slice(4, 6)}-${input.slice(6)}`);
    }
  };

  const sendSmsCode = async () => {
    try {
      await ssoRequest('/api/v1/sms', { phone, application: 'hearton' });
      setIsCodeSent(true);
      alert('인증 코드가 전송되었습니다.');
      
      localStorage.setItem('lastSmsRequestTime', new Date().toISOString());
      startThreeMinuteTimer();
      checkPhoneAttempts();
    } catch (error) {
      //console.error('SMS 전송 오류:', error);
      alert('문자 전송에 실패했습니다. 다시 시도해주세요.');
    }
  };

  const verifySmsCode = async () => {
    try {
      await ssoRequest('/api/v1/sms/verification', { code, phone });
      alert('인증 성공');
      handleSignup();
    } catch (error) {
      //console.error('SMS 인증 오류:', error);
      alert('인증에 실패했습니다. 올바른 코드를 입력했는지 확인하세요.');
    }
  };

  const handleSignup = async () => {
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    try {
      const url = `/doctor/sign-up`;
      await postRequest(application, url, {
        account,
        password,
        name,
        nickname,
        birthdate,
        phone,
      });
      alert('회원가입에 성공했습니다.');
      onRequestClose();
    } catch (error) {
      //console.error('회원가입 오류:', error);
      alert('회원가입에 실패했습니다.');
    }
  };

  const startThreeMinuteTimer = () => {
    clearInterval(threeMinuteTimerRef.current);
    setThreeMinuteTimer(180);
    threeMinuteTimerRef.current = setInterval(() => {
      setThreeMinuteTimer((prev) => {
        if (prev > 1) return prev - 1;
        clearInterval(threeMinuteTimerRef.current);
        return 0;
      });
    }, 1000);
  };

  const startTenMinuteTimer = () => {
    clearInterval(tenMinuteTimerRef.current);
    setTenMinuteTimer(600);
    setIsTenMinuteLimit(true);
    tenMinuteTimerRef.current = setInterval(() => {
      setTenMinuteTimer((prev) => {
        if (prev > 1) return prev - 1;
        clearInterval(tenMinuteTimerRef.current);
        setIsTenMinuteLimit(false);
        return 0;
      });
    }, 1000);
  };

  const checkPhoneAttempts = () => {
    const phoneAttempts = JSON.parse(localStorage.getItem('phoneAttempts') || '[]');
    phoneAttempts.push(new Date().toISOString());
    localStorage.setItem('phoneAttempts', JSON.stringify(phoneAttempts.slice(-3)));

    if (phoneAttempts.length >= 3) {
      const firstAttemptTime = new Date(phoneAttempts[0]);
      const elapsed = Math.floor((new Date() - firstAttemptTime) / 1000);
      if (elapsed < 600) {
        startTenMinuteTimer();
      }
    }
  };

  useEffect(() => {
    return () => {
      clearInterval(threeMinuteTimerRef.current);
      clearInterval(tenMinuteTimerRef.current);
    };
  }, []);

  const formatTime = (time) => {
    const minutes = String(Math.floor(time / 60)).padStart(2, '0');
    const seconds = String(time % 60).padStart(2, '0');
    return `${minutes}:${seconds}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      ariaHideApp={false}
      contentLabel="Signup Modal"
      className="signup-modal"
    >
      <h2>회원가입</h2>
      <form className="signup-form">
        {step === 1 && (
          <>
            <input
              type="text"
              placeholder="Account"
              value={account}
              onChange={(e) => setAccount(e.target.value)}
              required
              disabled={userInfo !== null}
            />
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              disabled={userInfo !== null}
            />
            <input
              type="password"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              disabled={userInfo !== null}
            />
            <input
              type="text"
              placeholder="Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
              required
              disabled={userInfo !== null}
            />
            <button type="button" onClick={nextStep}>다음</button>
          </>
        )}
        {step === 2 && (
  <>
    <input
      type="text"
      placeholder="Name"
      value={name}
      onChange={(e) => setName(e.target.value)}
      required
    />
    <input
      type="text"
      placeholder="Birthdate (YYYY-MM-DD)"
      value={birthdate}
      onChange={handleBirthdateChange}
      maxLength={10}
      required
    />
    <input
      type="text"
      placeholder="Phone"
      value={phone}
      onChange={handlePhoneChange}
      required
    />
    <button type="button" onClick={sendSmsCode} disabled={isTenMinuteLimit}>
      {isCodeSent ? '코드 다시 보내기' : '인증 코드 보내기'}
    </button>
    {isTenMinuteLimit && (
      <div className="timer-text">재인증 요청 가능 시간: {formatTime(tenMinuteTimer)}</div>
    )}
    {isCodeSent && (
      <>
        <div className="timer-text">인증번호 유효시간: {formatTime(threeMinuteTimer)}</div>
        <input
          type="text"
          placeholder="인증 코드 입력"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          required
        />
        <button type="button" onClick={verifySmsCode}>인증하기</button>
      </>
    )}
    {/* userInfo가 없거나 account가 없을 때만 이전 버튼을 표시 */}
    {(!userInfo || !userInfo.account) && (
      <button type="button" onClick={prevStep}>이전</button>
    )}
  </>
)}

      </form>
      <h10 style={{textAlign: 'center', color: '#888', marginTop: '10px', fontSize: '12px'}}>창 바깥을 누르면, 창이 닫힙니다.</h10>
    </Modal>
  );
};

export default SignupModal;
