import React, { useEffect, useState } from 'react';
import { getRequest } from '../UrlRequest';
import DataChart from './DataChart';
import { useLocation } from 'react-router-dom';

const Weight = ({ patientId, selectedDate, selectedPeriod }) => {
  const [weightData, setWeightData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const application = location.state?.application || 'hearton';

  useEffect(() => {
    const fetchWeightData = async () => {
      if (!patientId || !selectedDate) return;

      setLoading(true);
      setError(null);
      setWeightData(null);

      try {
        const token = localStorage.getItem('accessToken');
        
        // 기간 데이터만 가져오도록 수정
        const periodResponse = await getRequest(application,
          `/doctor/${patientId}/weight/period?on-date=${selectedDate}&period=${selectedPeriod}`,
          token
        );

        if (periodResponse && periodResponse.avg_weights?.length > 0) {
          setWeightData({
            weight: periodResponse.avg_weights[periodResponse.avg_weights.length - 1],
            weights: {
              dates: periodResponse.dates || [],
              values: periodResponse.avg_weights || [],
              is_dummy: periodResponse.is_dummy || []
            }
          });
        } else {
          setError('해당 기간에 체중 데이터가 없습니다.');
        }
      } catch (error) {
        setError('체중 데이터를 불러오는 중 오류가 발생했습니다.');
      } finally {
        setLoading(false);
      }
    };

    fetchWeightData();
  }, [application, patientId, selectedDate, selectedPeriod]);

  if (loading) return <p><strong>로딩 중...</strong></p>;

  return weightData ? (
    <div className="chartcontainer">
      <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
        <h3>체중</h3>
        <span style={{ fontSize: '14px', color: '#666' }}>
          현재: <strong style={{ color: '#2196f3' }}>{weightData.weight} kg</strong>
        </span>
      </div>
      <DataChart
        patientId={patientId}
        dataType="weight"
        endpoint={`/doctor/${patientId}/weight/period`}
        label="평균 체중"
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod}
        chartLabels={weightData.weights.dates}
        chartData={weightData.weights.values}
        chartDummy={weightData.weights.is_dummy}
      />
      {error && <p>{error}</p>}
    </div>
  ) : (
    <div className="chartcontainer">
      <h3>체중(kg)</h3>
      <p>{selectedDate}에 대한 체중 데이터가 없습니다.</p>
      <DataChart
        patientId={patientId}
        dataType="weight"
        endpoint={`/doctor/${patientId}/weight/period`}
        label="평균 체중"
        selectedDate={selectedDate}
        selectedPeriod={selectedPeriod}
        chartLabels={[]}
        chartData={[]}
        chartDummy={[]}
      />
      {error && <p>{error}</p>}
    </div>
  );
};

export default Weight;
