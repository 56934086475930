import React, { useState, useEffect } from 'react';
import { getRequest, postRequest, putRequest } from './UrlRequest';
import { useLocation } from 'react-router-dom';

const DeviceStatusForm = ({ patientId, isListView }) => {
  const [formData, setFormData] = useState({
    diagnosis: '',
    surgery_date: '',
    device_type: '',
    insertion_approach: '',
    monitoring_enable: '',
    mri_enable: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [deviceStatusList, setDeviceStatusList] = useState([]);
  const [editStatusId, setEditStatusId] = useState(null); // 수정할 기기 상태 ID 저장
  const [showAllDevices, setShowAllDevices] = useState(false);
  const location = useLocation();
  const application = location.state?.application || 'hearton';
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1366px)');
    const handleResize = (e) => setIsSmallScreen(e.matches);
    
    setIsSmallScreen(mediaQuery.matches);
    mediaQuery.addListener(handleResize);
    
    return () => mediaQuery.removeListener(handleResize);
  }, []);

  // 기기 상태 리스트 가져오기
  const fetchDeviceStatusList = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('accessToken');
      const data = await getRequest(application, `/doctor/${patientId}/device-statuses`, token);
      const formattedList = data.device_statuses.map((item) => {
        const endDate = item.interval.end_date === '현재' ? new Date().toISOString().slice(0, 10) : item.interval.end_date;
        return {
          id: item.device_status.device_status_id,
          startDate: item.interval.start_date,
          endDate: endDate,
          diagnosis: item.device_status.diagnosis,
          deviceType: item.device_status.device_type,
          insertionApproach: item.device_status.insertion_approach,
          monitoring_enable: item.device_status.monitoring_enable,
          mri_enable: item.device_status.mri_enable
        };
      }).sort((a, b) => new Date(b.startDate) - new Date(a.startDate)); // 날짜 내림차순 정렬 추가
      setDeviceStatusList(formattedList);
    } catch (error) {
      //console.error('Error fetching device statuses:', error);
      setError('기기 상태 목록을 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 초기 데이터 로드
  useEffect(() => {
    if (patientId) {
      fetchDeviceStatusList();
    }
  }, [patientId]);

  // 입력값 변경 처리
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // 수정 버튼 클릭 시 수정 모드로 전환하고 폼 데이터 설정
  const handleEditClick = (status) => {
    setEditStatusId(status.id);
    setFormData({
      diagnosis: status.diagnosis,
      surgery_date: status.startDate,
      device_type: status.deviceType,
      insertion_approach: status.insertionApproach,
      monitoring_enable: status.monitoring_enable,
      mri_enable: status.mri_enable,
    });
  };

  // 수정 폼 닫기
  const handleCancelEdit = () => {
    setEditStatusId(null);
    setFormData({
      diagnosis: '',
      surgery_date: '',
      device_type: '',
      insertion_approach: '',
      monitoring_enable: '',
      mri_enable: ''
    });
  };

  // 수정 폼 제출
  const handleEditSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('accessToken');
      await putRequest(application, `/doctor/device-status/${editStatusId}`, formData, token);
      alert('기기 상태가 성공적으로 수정되었습니다.');
      setEditStatusId(null); // 수정 모드 종료
      fetchDeviceStatusList(); // 목록 갱신
    } catch (error) {
      //console.error('Error updating device status:', error);
      setError('기기 상태 수정에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 작성 폼 제출
  const handleCreateSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('accessToken');
      await postRequest(application, `/doctor/${patientId}/device-status`, formData, token);
      alert('기기 상태가 성공적으로 작성되었습니다.');
      setFormData({
        diagnosis: '',
        surgery_date: '',
        device_type: '',
        insertion_approach: '',
        monitoring_enable: '',
        mri_enable: ''
      });
      fetchDeviceStatusList(); // 목록 갱신
    } catch (error) {
      //console.error('Error creating device status:', error);
      setError('기기 상태 작성에 실패했습니다.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="device-status-container" style={{
      width: '100%',
      height: isListView ? 'calc(100vh - 120px)' : 'auto', // 목록 보기일 때만 전체 높이 설정
      display: 'flex',
      flexDirection: 'column'
    }}>
      {isListView ? (
        <div className="device-status-list" style={{
          width: '50%',
          display: 'flex',
          flexDirection: 'column',
          gap: '8px'
        }}>
          <h2 style={{ 
            margin: '0',
            fontSize: '16px',
            fontWeight: '500'
          }}>기기 상태 목록</h2>

          {error && (
            <div style={{
              marginBottom: '16px',
              color: 'red',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }}>
              <span>{error}</span>
              <button
                onClick={fetchDeviceStatusList}
                style={{
                  padding: '6px 12px',
                  backgroundColor: '#e53e3e',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px'
                }}
              >
                다시 시도
              </button>
            </div>
          )}
          {loading ? (
            <div style={{
              textAlign: 'center',
              padding: '20px',
              color: '#4a5568'
            }}>로딩 중...</div>
          ) : (
            <div style={{ 
              display: 'flex',
              flexDirection: 'column',
              gap: '8px'
            }}>
              {deviceStatusList.length > 0 && (
                <div style={{ height: '100%' }}>
                  {!showAllDevices ? (
                    <>
                      {deviceStatusList.length > 1 && (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginBottom: '16px'
                        }}>
                          <button
                            onClick={() => setShowAllDevices(true)}
                            style={{
                              padding: '6px 12px',
                              backgroundColor: '#48bb78',
                              color: 'white',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              fontSize: '14px'
                            }}
                          >
                            모든 기록 보기
                          </button>
                        </div>
                      )}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '24px',
                        marginBottom: '16px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        border: '1px solid #e2e8f0',
                        height: '200px'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '8px'
                        }}>
                          <h3 style={{
                            fontSize: '16px',
                            margin: '0px',
                            fontWeight: '500',
                            color: '#2d3748'
                          }}>{deviceStatusList[0].diagnosis}</h3>
                          <button 
                            onClick={() => handleEditClick(deviceStatusList[0])}
                            style={{
                              padding: '6px 12px',
                              backgroundColor: '#4299e1',
                              color: 'white',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              fontSize: '14px'
                            }}
                          >
                            수정
                          </button>
                        </div>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gap: '12px',
                          fontSize: '16px',
                          color: '#4a5568',
                          height: '140px'
                        }}>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>기간:</span>
                            {deviceStatusList[0].startDate} ~ {deviceStatusList[0].endDate}
                          </p>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>기기 유형:</span>
                            {deviceStatusList[0].deviceType}
                          </p>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>삽입 접근 방식:</span>
                            {deviceStatusList[0].insertionApproach}
                          </p>
                          <div style={{
                            display: 'flex',
                            gap: '20px',
                            margin: '0px'
                          }}>
                            <p style={{
                              display: 'flex',
                              gap: '8px',
                              alignItems: 'center',
                              margin: '0px'
                            }}>
                              <span style={{ fontWeight: '500' }}>모니터링:</span>
                              {deviceStatusList[0].monitoring_enable}
                            </p>
                            <p style={{
                              display: 'flex',
                              gap: '8px',
                              alignItems: 'center',
                              margin: '0px'
                            }}>
                              <span style={{ fontWeight: '500' }}>MRI:</span>
                              {deviceStatusList[0].mri_enable}
                            </p>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px'
                      }}>
                        <h3 style={{
                          fontSize: '18px',
                          margin: '0px',
                          fontWeight: '500',
                          color: '#2d3748'
                        }}>전체 기기 상태 기록</h3>
                        <button
                          onClick={() => setShowAllDevices(false)}
                          style={{
                            padding: '6px 12px',
                            backgroundColor: '#48bb78',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '14px'
                          }}
                        >
                          최신 기록만 보기
                        </button>
                      </div>
                      <div style={{
                        overflowY: 'auto',
                        maxHeight: 'calc(100% - 60px)',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                      }}>
                        {deviceStatusList.map((status) => (
                          <div key={status.id} style={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '16px',
                            marginBottom: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            border: '1px solid #e2e8f0'
                          }}>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginBottom: '8px'
                            }}>
                              <h3 style={{
                                fontSize: '16px',
                                margin: '0px',
                                fontWeight: '500',
                                color: '#2d3748'
                              }}>{status.diagnosis}</h3>
                              <button 
                                onClick={() => handleEditClick(status)}
                                style={{
                                  padding: '6px 12px',
                                  backgroundColor: '#4299e1',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  fontSize: '14px'
                                }}
                              >
                                수정
                              </button>
                            </div>

                            <div style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(2, 1fr)',
                              gap: '8px',
                              fontSize: '14px',
                              color: '#4a5568'
                            }}>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>기간:</span>
                                {status.startDate} ~ {status.endDate}
                              </p>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>기기 유형:</span>
                                {status.deviceType}
                              </p>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>삽입 접근 방식:</span>
                                {status.insertionApproach}
                              </p>
                              <div style={{
                                display: 'flex',
                                gap: '20px',
                                margin: '0px'
                              }}>
                                <p style={{
                                  display: 'flex',
                                  gap: '8px',
                                  alignItems: 'center',
                                  margin: '0px'
                                }}>
                                  <span style={{ fontWeight: '500' }}>모니터링:</span>
                                  {status.monitoring_enable}
                                </p>
                                <p style={{
                                  display: 'flex',
                                  gap: '8px',
                                  alignItems: 'center',
                                  margin: '0px'
                                }}>
                                  <span style={{ fontWeight: '500' }}>MRI:</span>
                                  {status.mri_enable}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className="device-status-container" style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gap: '20px',
          height: 'calc(62vh - 100px)', // 전체 높이에서 상단 여백을 뺀 높이
          overflow: 'hidden' // 컨테이너 자체의 오버플로우는 숨김
        }}>
          {/* 왼쪽: 기기 상태 목록 */}
          <div style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            height: isSmallScreen ? '50vh' : '52vh',
            overflowY: 'auto',
            minHeight: '40%',
            maxHeight: '100%',
          }}>
            {error && (
              <div style={{
                marginBottom: '16px',
                color: 'red',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center'
              }}>
                <span>{error}</span>
                <button
                  onClick={fetchDeviceStatusList}
                  style={{
                    padding: '6px 12px',
                    backgroundColor: '#e53e3e',
                    color: 'white',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '14px'
                  }}
                >
                  다시 시도
                </button>
              </div>
            )}
            {loading && <p>로딩 중...</p>}
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {!loading && !error && deviceStatusList.length === 0 && (
              <p>기기 상태 정보가 없습니다.</p>
            )}
            {!loading && !error && deviceStatusList.length > 0 && (
              <div style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                overflowY: 'auto'
              }}>
                <div style={{ height: '100%', overflowY: 'auto' }}>
                  {!showAllDevices ? (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      overflowY: 'auto'
                    }}>
                      {deviceStatusList.length > 1 && (
                        <div style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          marginBottom: '16px'
                        }}>
                          <button
                            onClick={() => setShowAllDevices(true)}
                            style={{
                              padding: '6px 12px',
                              backgroundColor: '#48bb78',
                              color: 'white',
                              border: 'none',
                              borderRadius: '4px',
                              cursor: 'pointer',
                              fontSize: '14px'
                            }}
                          >
                            모든 기록 보기
                          </button>
                        </div>
                      )}
                      <div style={{
                        backgroundColor: 'white',
                        borderRadius: '8px',
                        padding: '24px',
                        marginBottom: '16px',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                        border: '1px solid #e2e8f0',
                        height: '200px'
                      }}>
                        <div style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          alignItems: 'center',
                          marginBottom: '8px'
                        }}>
                          <h3 style={{
                            fontSize: '16px',
                            margin: '0px',
                            fontWeight: '500',
                            color: '#2d3748'
                          }}>{deviceStatusList[0].diagnosis}</h3>
                          <div style={{ display: 'flex', gap: '8px' }}>
                            <button 
                              onClick={() => handleEditClick(deviceStatusList[0])}
                              style={{
                                padding: '6px 12px',
                                backgroundColor: '#4299e1',
                                color: 'white',
                                border: 'none',
                                borderRadius: '4px',
                                cursor: 'pointer',
                                fontSize: '14px'
                              }}
                            >
                              수정
                            </button>
                          </div>
                        </div>
                        <div style={{
                          display: 'grid',
                          gridTemplateColumns: 'repeat(2, 1fr)',
                          gap: '12px',
                          fontSize: '16px',
                          color: '#4a5568',
                          height: '140px'
                        }}>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>기간:</span>
                            {deviceStatusList[0].startDate} ~ {deviceStatusList[0].endDate}
                          </p>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>기기 유형:</span>
                            {deviceStatusList[0].deviceType}
                          </p>
                          <p style={{
                            display: 'flex',
                            gap: '8px',
                            alignItems: 'center',
                            margin: '0px'
                          }}>
                            <span style={{ fontWeight: '500' }}>삽입 접근 방식:</span>
                            {deviceStatusList[0].insertionApproach}
                          </p>
                          <div style={{
                            display: 'flex',
                            gap: '20px',
                            margin: '0px'
                          }}>
                            <p style={{
                              display: 'flex',
                              gap: '8px',
                              alignItems: 'center',
                              margin: '0px'
                            }}>
                              <span style={{ fontWeight: '500' }}>모니터링:</span>
                              {deviceStatusList[0].monitoring_enable}
                            </p>
                            <p style={{
                              display: 'flex',
                              gap: '8px',
                              alignItems: 'center',
                              margin: '0px'
                            }}>
                              <span style={{ fontWeight: '500' }}>MRI:</span>
                              {deviceStatusList[0].mri_enable}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100%',
                      overflowY: 'auto'
                    }}>
                      <div style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '16px'
                      }}>
                        <h3 style={{
                          fontSize: '18px',
                          margin: '0px',
                          fontWeight: '500',
                          color: '#2d3748'
                        }}>전체 기기 상태 기록</h3>
                        <button
                          onClick={() => setShowAllDevices(false)}
                          style={{
                            padding: '6px 12px',
                            backgroundColor: '#48bb78',
                            color: 'white',
                            border: 'none',
                            borderRadius: '4px',
                            cursor: 'pointer',
                            fontSize: '14px'
                          }}
                        >
                          최신 기록만 보기
                        </button>
                      </div>
                      <div style={{
                        flex: 1,
                        overflowY: 'auto',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px'
                      }}>
                        {deviceStatusList.map((status) => (
                          <div key={status.id} style={{
                            backgroundColor: 'white',
                            borderRadius: '8px',
                            padding: '16px',
                            marginBottom: '8px',
                            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
                            border: '1px solid #e2e8f0'
                          }}>
                            <div style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                              marginBottom: '8px'
                            }}>
                              <h3 style={{
                                fontSize: '16px',
                                margin: '0px',
                                fontWeight: '500',
                                color: '#2d3748'
                              }}>{status.diagnosis}</h3>
                              <button 
                                onClick={() => handleEditClick(status)}
                                style={{
                                  padding: '6px 12px',
                                  backgroundColor: '#4299e1',
                                  color: 'white',
                                  border: 'none',
                                  borderRadius: '4px',
                                  cursor: 'pointer',
                                  fontSize: '14px'
                                }}
                              >
                                수정
                              </button>
                            </div>

                            <div style={{
                              display: 'grid',
                              gridTemplateColumns: 'repeat(2, 1fr)',
                              gap: '8px',
                              fontSize: '14px',
                              color: '#4a5568'
                            }}>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>기간:</span>
                                {status.startDate} ~ {status.endDate}
                              </p>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>기기 유형:</span>
                                {status.deviceType}
                              </p>
                              <p style={{
                                display: 'flex',
                                gap: '8px',
                                alignItems: 'center',
                                margin: '0px'
                              }}>
                                <span style={{ fontWeight: '500' }}>삽입 접근 방식:</span>
                                {status.insertionApproach}
                              </p>
                              <div style={{
                                display: 'flex',
                                gap: '20px',
                                margin: '0px'
                              }}>
                                <p style={{
                                  display: 'flex',
                                  gap: '8px',
                                  alignItems: 'center',
                                  margin: '0px'
                                }}>
                                  <span style={{ fontWeight: '500' }}>모니터링:</span>
                                  {status.monitoring_enable}
                                </p>
                                <p style={{
                                  display: 'flex',
                                  gap: '8px',
                                  alignItems: 'center',
                                  margin: '0px'
                                }}>
                                  <span style={{ fontWeight: '500' }}>MRI:</span>
                                  {status.mri_enable}
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>

          {/* 오른쪽: 기기 상태 작성 */}
          <div className="device-status-form" style={{
            backgroundColor: 'white',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
            position: 'sticky',
            top: '20px',
            height: 'fit-content'
          }}>
            <h2 style={{ 
              margin: '0',
              fontSize: '16px',
              fontWeight: '500'
            }}>{editStatusId ? '기기 상태 수정' : '기기 상태 작성'}</h2>
        
            <form onSubmit={editStatusId ? handleEditSubmit : handleCreateSubmit} style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(2, 1fr)',
              gap: '12px',
              alignItems: 'start'
            }}>
              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>진단명:</label>
                <input
                  type="text"
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="diagnosis"
                  value={formData.diagnosis}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>수술일:</label>
                <input
                  type="date"
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="surgery_date"
                  value={formData.surgery_date}
                  onChange={handleInputChange}
                  required
                />
              </div>

              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>기기 유형:</label>
                <select
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="device_type"
                  value={formData.device_type}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="심박기(Pacemaker)">심박기(Pacemaker)</option>
                  <option value="삽입형제세동기(ICD)">삽입형제세동기(ICD)</option>
                  <option value="심장재동기화치료기(CRT-D)">심장재동기화치료기(CRT-D)</option>
                  <option value="심장재동기화치료기(CRT-P)">심장재동기화치료기(CRT-P)</option>
                </select>
              </div>

              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>삽입 접근 방식:</label>
                <select
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="insertion_approach"
                  value={formData.insertion_approach}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="개흉">개흉</option>
                  <option value="경정맥">경정맥</option>
                  <option value="피하">피하</option>
                </select>
              </div>

              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>모니터링 가능 여부:</label>
                <select
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="monitoring_enable"
                  value={formData.monitoring_enable}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="유">유</option>
                  <option value="무">무</option>
                </select>
              </div>

              <div style={{
                display: 'grid',
                gap: '4px',
                width: '100%',
                justifyItems: 'center',
                alignItems: 'center'
              }}>
                <label style={{ 
                  fontSize: '14px',
                  justifySelf: 'start'
                }}>MRI 가능 여부:</label>
                <select
                  style={{
                    width: '140px',
                    padding: '6px',
                    border: '1px solid #ddd',
                    borderRadius: '4px',
                    fontSize: '14px',
                    boxSizing: 'border-box'
                  }}
                  name="mri_enable"
                  value={formData.mri_enable}
                  onChange={handleInputChange}
                  required
                >
                  <option value="">선택하세요</option>
                  <option value="유">유</option>
                  <option value="무">무</option>
                </select>
              </div>

              <button
                type="submit"
                style={{
                  padding: '6px 12px',
                  backgroundColor: '#4299e1',
                  color: 'white',
                  border: 'none',
                  borderRadius: '4px',
                  cursor: 'pointer',
                  fontSize: '14px',
                  transition: 'background-color 0.2s ease',
                  ':hover': {
                    backgroundColor: '#3182ce'
                  }
                }}>
                {editStatusId ? '수정 완료' : '작성'}
              </button>
              {editStatusId && (
                <button
                  type="button"
                  onClick={handleCancelEdit}
                  style={{
                    padding: '6px 12px',
                    backgroundColor: '#e2e8f0',
                    color: '#4a5568',
                    border: 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                    fontSize: '14px',
                    fontWeight: '500',
                    transition: 'background-color 0.2s ease',
                    ':hover': {
                      backgroundColor: '#cbd5e0'
                    }
                  }}
                >
                  작성폼으로
                </button>
              )}
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeviceStatusForm;
