import React, { useEffect, useState } from 'react';
import { getRequest, getBaseUrl } from '../UrlRequest';
import { useLocation } from 'react-router-dom';

const StatusItem = ({ label, value }) => (
  <div style={{
    backgroundColor: '#f8f9fa',
    borderRadius: '6px',
    boxShadow: '0 1px 3px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.2s ease',
    ':hover': {
      transform: 'translateY(-2px)',
      boxShadow: '0 2px 5px rgba(0, 0, 0, 0.15)'
    }
  }}>
    <strong style={{
      color: '#34495e',
      display: 'block',
      marginBottom: '5px',
      fontSize: '0.9rem'
    }}>{label}:</strong>
    <span style={{
      color: '#2c3e50',
      fontSize: '1rem'
    }}>{value}</span>
  </div>
);

const Condition = ({ patientId, selectedDate }) => {
  const [conditionData, setConditionData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [surgeryImage, setSurgeryImage] = useState(null);
  const location = useLocation();
  const application = location.state?.application || 'hearton';

  const fetchSurgeryImage = async (healthConditionId, token) => {
    try {
      const response = await fetch(`${getBaseUrl(application)}/doctor/${healthConditionId}/surgery-image`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Cache-Control': 'no-cache',
          Pragma: 'no-cache',
        },
      });
      if (!response.ok) throw new Error('이미지를 불러오지 못했습니다.');
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    } catch (error) {
      console.error(`Health Condition ID ${healthConditionId} 이미지 오류:`, error);
      return null;
    }
  };

  useEffect(() => {
    const fetchConditionData = async () => {
      setConditionData(null);
      setError(null);
      setLoading(true);
      setSurgeryImage(null);

      try {
        const token = localStorage.getItem('accessToken');
        if (!token) {
          setError('인증 토큰이 없습니다.');
          setLoading(false);
          return;
        }

        const data = await getRequest(application, `/doctor/${patientId}/health-condition/daily?on-date=${selectedDate}`, token);
        if (data) {
          setConditionData(data);

          // health_condition_id가 있는 경우 이미지 가져오기
          if (data.health_condition_id) {
            const imageUrl = await fetchSurgeryImage(data.health_condition_id, token);
            setSurgeryImage(imageUrl);
          }
        } else {
          setError('해당 날짜에 건강 상태 데이터가 없습니다.');
        }
      } catch (error) {
        setError('건강 상태 데이터를 불러오지 못했습니다.');
      } finally {
        setLoading(false);
      }
    };

    if (patientId && selectedDate) {
      fetchConditionData();
    }
  }, [application, patientId, selectedDate]);

  if (loading) return <p>환자의 데이터를 클릭하면 로드 합니다.</p>;
  if (error) return <p>{error}</p>;

  return conditionData ? (
    <div style={{
      height: 'calc(100% - 10vh)',
      overflow: 'auto',
      padding: '15px'
    }}>
      <h3 style={{
        color: '#2c3e50',
        borderBottom: '2px solid #3498db',
        paddingBottom: '10px',
        marginBottom: '20px',
        top: 0,
        backgroundColor: 'white',
      }}>건강 상태</h3>
      
      <div style={{
        display: 'grid',
        gridTemplateColumns: surgeryImage ? '250px 1fr' : '1fr',
        gap: '20px',
        alignItems: 'start'
      }}>
        {/* 이미지 섹션 */}
        {surgeryImage && (
          <div style={{
            padding: '15px',
            backgroundColor: '#f8f9fa',
            borderRadius: '8px',
            position: 'sticky',
            top: '70px'
          }}>
            <h4 style={{
              color: '#2c3e50',
              marginBottom: '15px'
            }}>수술 이미지</h4>
            <img 
              src={surgeryImage} 
              alt="수술 이미지" 
              style={{
                width: '100%',
                borderRadius: '8px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
              }} 
            />
          </div>
        )}
  
        {/* 오른쪽 컨테이너 */}
        <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
          {/* 상태 정보 섹션 */}
          <div style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(180px, 1fr))',
            gap: '15px'
          }}>
            <StatusItem label="실신" value={conditionData.fainting} />
            <StatusItem label="소변 감소" value={conditionData.urine_reduction} />
            <StatusItem label="불편감" value={conditionData.discomfort} />
            <StatusItem label="식사 상태" value={conditionData.diet_status} />
            <StatusItem label="운동 빈도" value={conditionData.exercise_frequency || '없음'} />
            <StatusItem label="심박수(BPM)" value={`${conditionData.heart_rate || '없음'}`} />
            <StatusItem label="수술 부위 상태" value={conditionData.surgery_site_condition || '없음'} />
            <StatusItem label="부종" value={conditionData.edema || '없음'} />
            <StatusItem label="심부전 클래스" value={conditionData.heart_failure_class || '없음'} />
            <StatusItem label="약물 복용 상태" value={conditionData.medication_status} />
            <StatusItem label="기기 알람" value={conditionData.device_alarm || '없음'} />
          </div>

          {/* 상세 증상 섹션 */}
          <div style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}>
            {conditionData.discomfort === '있음' && (
              <div style={{
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                padding: '15px'
              }}>
                <h4 style={{
                  color: '#2c3e50',
                  marginBottom: '10px'
                }}>불편감 상세 증상</h4>
                <div style={{
                  maxHeight: '120px',
                  overflowY: 'auto',
                  padding: '10px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                }}>
                  {conditionData.discomfort_symptom || '없음'}
                </div>
              </div>
            )}

            {conditionData.surgery_site_condition === '열감' && (
              <div style={{
                backgroundColor: '#f8f9fa',
                borderRadius: '8px',
                padding: '15px'
              }}>
                <h4 style={{
                  color: '#2c3e50',
                  marginBottom: '10px'
                }}>수술 부위 상세 증상</h4>
                <div style={{
                  maxHeight: '120px',
                  overflowY: 'auto',
                  padding: '10px',
                  backgroundColor: 'white',
                  borderRadius: '6px',
                  boxShadow: 'inset 0 1px 3px rgba(0, 0, 0, 0.1)'
                }}>
                  {conditionData.surgery_site_symptom || '없음'}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <p>건강 상태 데이터가 없습니다.</p>
  );
};

export default Condition;