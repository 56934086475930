import React, { useMemo, useRef, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, registerables } from 'chart.js';
import { format, parseISO } from 'date-fns';

ChartJS.register(...registerables);

const Chart = ({ labels, data, data2, label, label2, dummyData }) => {
  const formattedLabels = labels.map((date) => format(parseISO(date), 'MM-dd'));
  const canvasRef = useRef(null); //캔버스 참조용
  const chartInstance = useRef(null); //차트 인스턴스 참조용

  useEffect(() => {
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }
    //데이터 수가 30개 초과일 때만 점 크기 축소
    const pointRadius = data.length > 30 ? 0 : 5;

    //데이터 최솟값과 최댓값 계산
    const allData = [...data, ...(data2 || [])];
    const minValue = Math.min(...allData);
    const maxValue = Math.max(...allData);

    const yAxisOptions = data.length > 30 
      ? { min: minValue - 20, max: maxValue + 20 } 
      : { min: minValue - 10, max: maxValue + 10};

    //새 차트 인스턴스 생성
    chartInstance.current = new ChartJS(canvasRef.current, {
      type: 'line',
      data: {
        labels: formattedLabels,
        datasets: [
          {
            label,
            data,
            fill: false,
            borderColor: 'rgba(75,192,192,1)',
            tension: 0.1,
            borderWidth: 1,
            pointBackgroundColor: 'rgba(75,192,192,1)',
            pointRadius: data.map((_, index) => (dummyData[index] ? 0 : pointRadius)),
          },
          ...(data2
            ? [
                {
                  label: label2,
                  data: data2,
                  fill: false,
                  borderColor: 'rgba(255,99,132,1)',
                  tension: 0.1,
                  borderWidth: 1,
                  pointBackgroundColor: 'rgba(255,99,132,1)',
                  pointRadius: data2.map((_, index) => (dummyData[index] ? 0 : pointRadius)),
                },
              ]
            : []),
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: 0 // 모든 애니메이션 비활성화
        },
        transitions: {
          active: {
            animation: {
              duration: 0 // 전환 애니메이션 비활성화
            }
          }
        },
        plugins: {
          legend: {
            display: true,
            position: 'top',
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.dataset.label}: ${context.raw}`,
            },
          },
        },
        scales: {
          x: {
            type: 'category',
            title: {
              display: true,
              font: { size: 16 },
            },
            ticks: {
              maxRotation: 45,
              minRotation: 0,
            },

          },
          y: {
            title: {
              display: true,
              font: { size: 16 },
            },
            ...yAxisOptions, //데이터 수에 따라 min/max 적용
          },
        },
      },
    });

    //컴포넌트가 unmount될 때 차트 인스턴스 제거
    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };

  }, [labels, data, data2, dummyData]);
  const chartWidth = useMemo(() => {
    const labelCount = labels.length;
    return labelCount > 29 ? '100%' : labelCount * 50;
  }, [labels]);

/*
  const chartData = {
    labels: formattedLabels,
    datasets: [
      {
        label,
        data,
        fill: false,
        borderColor: 'rgba(75,192,192,1)',
        tension: 0.1,
        borderWidth: 2,
        pointBackgroundColor: 'rgba(75,192,192,1)',
      },
      ...(data2
        ? [
            {
              label: label2,
              data: data2,
              fill: false,
              borderColor: 'rgba(255,99,132,1)',
              tension: 0.1,
              borderWidth: 2,
              pointBackgroundColor: 'rgba(255,99,132,1)',
            },
          ]
        : []),
    ],
  };

  const options = {
    maintainAspectRatio: false, // 부모 컨테이너에 맞게 조정
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        callbacks: {
          label: (context) => `${context.dataset.label}: ${context.raw}`,
        },
      },
    },
    scales: {
      x: {
        type: 'category',
        title: {
          display: true,
          font: { size: 16 },
        },
        ticks: {
          maxRotation: 45,
          minRotation: 0,
        },
      },
      y: {
        title: {
          display: true,
          text: '측정값',
          font: { size: 16 },
        },
      },
    },
  };
*/

  return (
    <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', height: '100%', width: '100%'}}>
      <div style={{ width: '100%', height: '100%', position: 'relative'}}>
        <canvas ref={canvasRef} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain', display: 'block' }}></canvas>
      </div>
    </div>
  );
};

export default Chart;
