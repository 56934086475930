import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import { getRequest } from '../UrlRequest';
import './ChatList.css';

export default function ChatList({ onVisibilityChange, patientId, patient, isVisible = false }) {
  const [messages, setMessages] = useState([]);
  const [nextCursor, setNextCursor] = useState(0);
  const [loading, setLoading] = useState(false);

  const fetchMessages = async (cursor = 0) => {
    if (!patientId || loading) return;
    
    try {
      setLoading(true);
      const size = 10;
      const token = localStorage.getItem('accessToken');
      const response = await getRequest(
        'hearton',
        `/doctor/chatbot/${patientId}/conversation?cursor=${cursor}&size=${size}`,
        token
      );

      if (response && response.messages) {
        if (cursor === 0) {
          setMessages(response.messages.sort((a, b) => b.answer.id - a.answer.id));
        } else {
          setMessages(prev => [...prev, ...response.messages].sort((a, b) => b.answer.id - a.answer.id));
        }
        setNextCursor(response.next_cursor); // null이 될 수 있음
      }
    } catch (error) {
      console.error('Failed to fetch chat messages:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (patientId && isVisible) {
      setMessages([]); // 이전 메시지 초기화
      setNextCursor(0); // 커서도 초기화
      fetchMessages(0);
    }
  }, [patientId, isVisible]);

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    // 스크롤이 하단에 도달했고, 다음 커서가 있고, 로딩 중이 아닐 때
    if (Math.abs(scrollHeight - clientHeight - scrollTop) < 1 && nextCursor && !loading) {
      fetchMessages(nextCursor);
      console.log('scroll bottom reached');
    }
  };

  return (
    <>
      {isVisible && (
        <div className="chatlist-container">
          <div className="chat-list-content" onScroll={handleScroll}>
            {messages.length > 0 ? (
              messages.map((chat, index) => (
                <div key={index} className="chat-item">
                  <div className="question">
                    <strong>{patient?.name || '환자'}:</strong> {chat.question.question}
                  </div>
                  <div className="answer">
                    <div className="markdown-content">
                      <ReactMarkdown>{chat.answer.answer}</ReactMarkdown>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="no-messages">메시지가 없습니다</div>
            )}
            {loading && <div className="loading">로딩 중...</div>}
          </div>
        </div>
      )}
    </>
  );
}
