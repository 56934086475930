import React, { useEffect, useState, useCallback, useRef } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button, CircularProgress, Paper, IconButton, Collapse, Typography, Tooltip,
} from '@mui/material';
import { getRequest } from '../UrlRequest';
import { useLocation } from 'react-router-dom';
import { CSVLink } from 'react-csv';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { utils, writeFile } from 'xlsx';

const tableStyles = {
  tableContainer: {
    '& .MuiTable-root': {
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    '& .MuiTableHead-root': {
      backgroundColor: '#f8f8f8',
    },
    '& .MuiTableHead-root .MuiTableCell-root': {
      borderBottom: '2px solid #BDC3C7',
      fontWeight: 'bold',
      color: '#000',
      backgroundColor: '#f8f8f8',
      padding: '16px', // Increased padding to create more margin
    },
    '& .MuiTableCell-root': {
      border: '1px solid #dde2eb',
      borderTop: 'none',
      borderLeft: 'none',
      fontSize: 'calc(var(--base-font-size) * var(--scale-factor))',
    },
    '& .MuiTableRow-root:hover': {
      backgroundColor: '#f4f4f4',
    },
    '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
      borderBottom: '1px solid #dde2eb',
    },
  }
};

const PatientGrid = ({ patientId, setSelectedDate, patient, onDataClick }) => {
  const [rowData, setRowData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [pageSize] = useState(15);
  const location = useLocation();
  const application = location.state?.application || 'hearton';
//스크롤 무한 로딩 추가
  const containerRef = useRef(null);
  const previousPatientId = useRef(null);
  const [initialLoad, setInitialLoad] = useState(false);

  const fetchData = useCallback(async (currentPage, currentPatientId) => {
    if (loading || !hasMore || (initialLoad && currentPage > 1)) return;
    setLoading(true);

    try {
      const token = localStorage.getItem('accessToken');
      const data = await getRequest(
        application, 
        `/doctor/health/${currentPatientId}?page=${currentPage}&size=${pageSize}`, 
        token
      );

      const healthList = data.health_list || [];
      const formattedData = healthList.map((record) => ({
        on_date: record.on_date,
        systolic: record.blood_pressures?.[0]?.systolic || '미등록',
        diastolic: record.blood_pressures?.[0]?.diastolic || '미등록',
        weight: record.weight || '미등록',
        heart_rate: record.heart_rates?.[0]?.heart_rate || record.health_condition?.heart_rate || '미등록',
        health_condition: record.health_condition || {},
        blood_pressures: record.blood_pressures || [],
        heart_rates: record.heart_rates || [],
        expanded: false,
      }));
      
      if (currentPatientId === patientId) {
        setRowData((prevData) => currentPage === 1 ? formattedData : [...prevData, ...formattedData]);
        setHasMore(healthList.length === pageSize);
      }
      setInitialLoad(false);
    } catch (error) {
      setError('데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  }, [application, pageSize, loading, hasMore, initialLoad, patientId]);

  useEffect(() => {
    if (previousPatientId.current !== patientId) {
      setRowData([]);
      setPage(1);
      setHasMore(true);
      setInitialLoad(true);
      
      previousPatientId.current = patientId;
      fetchData(1, patientId);
    }
  }, [patientId, fetchData]);

  useEffect(() => {
    if (initialLoad) {
      fetchData(1, patientId);
    }
  }, [fetchData, initialLoad, patientId]);

  const loadNextPage = useCallback(() => {
    if (hasMore && !loading) {
      const nextPage = page + 1;
      fetchData(nextPage, patientId);
      setPage(nextPage);
    }
  }, [hasMore, loading, fetchData, page, patientId]);

  const handleScroll = useCallback(() => {
    if (!containerRef.current) return;
    
    const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
    if (scrollHeight - scrollTop <= clientHeight + 100) {
      loadNextPage();
    }
  }, [loadNextPage]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [handleScroll]);

  const toggleRowExpansion = (index) => {
    setRowData((prevData) =>
      prevData.map((row, i) => (i === index ? { ...row, expanded: !row.expanded } : row))
    );
  };

  const handleRowClick = (date) => {
    setSelectedDate(date);
    if (onDataClick) {
      onDataClick(date);
    }
  };

  const Row = ({ row }) => {
    const [open, setOpen] = useState(false);
    
    return (
      <>
        <TableRow 
          hover 
          onClick={() => handleRowClick(row.on_date)}
          style={{ cursor: 'pointer' }}
        >
          <TableCell>
            <IconButton onClick={() => toggleRowExpansion(rowData.indexOf(row))}>
              {row.expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell>{row.on_date}</TableCell>
          <TableCell>{row.systolic}</TableCell>
          <TableCell>{row.diastolic}</TableCell>
          <TableCell>{row.weight}</TableCell>
          <TableCell>{row.heart_rate}</TableCell>
          <TableCell>{row.health_condition.fainting || '미등록'}</TableCell>
          <TableCell>{row.health_condition.urine_reduction || '미등록'}</TableCell>
          <TableCell>{row.health_condition.discomfort || '미등록'}</TableCell>
          <TableCell>{row.health_condition.edema || '미등록'}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
            <Collapse in={row.expanded} timeout="auto" unmountOnExit>
              <Table size="small" aria-label="시간별 데이터">
                <TableHead>
                  <TableRow>
                    <TableCell>시간</TableCell>
                    <TableCell>수축기 혈압</TableCell>
                    <TableCell>이완기 혈압</TableCell>
                    <TableCell>심박수</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(() => {
                    // 모든 시간대를 추출
                    const allTimes = [...new Set([
                      ...row.blood_pressures.map(bp => bp.on_time),
                      ...row.heart_rates.map(hr => hr.on_time)
                    ])].sort((a, b) => b.localeCompare(a));  // 시간 내림차순 정렬

                    // 각 시간대별로 데이터 통합
                    return allTimes.map((time, i) => {
                      const bp = row.blood_pressures.find(bp => bp.on_time === time) || {};
                      const hr = row.heart_rates.find(hr => hr.on_time === time) || {};

                      return (
                        <TableRow key={`time-${i}`}>
                          <TableCell>{time}</TableCell>
                          <TableCell>{bp.systolic || '미등록'}</TableCell>
                          <TableCell>{bp.diastolic || '미등록'}</TableCell>
                          <TableCell>{hr.heart_rate || '미등록'}</TableCell>
                        </TableRow>
                      );
                    });
                  })()}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  };

  const flattenRowData = rowData.flatMap((record) => {
    const baseData = {
      날짜: record.on_date,
      수축기혈압: '',
      이완기혈압: '',
      심박수: '',
      시간: '',
      체중: record.weight,
      심박수: record.heart_rate,
      실신: record.health_condition?.fainting || '미등록',
      소변감소: record.health_condition?.urine_reduction || '미등록',
      불편감: record.health_condition?.discomfort || '미등록',
      부종: record.health_condition?.edema || '미등록'
    };
  
    if (!record.blood_pressures.length && !record.heart_rates.length) {
      return [baseData];
    }
  
    const times = Array.from(new Set([
      ...record.blood_pressures.map((bp) => bp.on_time),
      ...record.heart_rates.map((hr) => hr.on_time),
    ])).sort((a, b) => b.localeCompare(a));  // 시간 내림차순 정렬
  
    return times.map((time) => {
      const bpData = record.blood_pressures.find((bp) => bp.on_time === time) || {};
      const hrData = record.heart_rates.find((hr) => hr.on_time === time) || {};
  
      return {
        날짜: record.on_date,       
        수축기혈압: bpData.systolic || '미등록',
        이완기혈압: bpData.diastolic || '미등록',
        심박수: hrData.heart_rate || '미등록',
        시간: time || '미등록',
        체중: record.weight,
        심박수: record.heart_rate,
        실신: record.health_condition?.fainting || '미등록',
        소변감소: record.health_condition?.urine_reduction || '미등록',
        불편감: record.health_condition?.discomfort || '미등록',
        부종: record.health_condition?.edema || '미등록'
      };
    });
  });
  
  const handleExcelDownload = () => {
    const ws = utils.json_to_sheet(flattenRowData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "환자데이터");
    writeFile(wb, `${patient.name}_${new Date().toISOString().split('T')[0]}.xlsx`);
  };

  return (
    <div style={{height: '100%', display: 'flex', flexDirection: 'column', overflow: 'hidden'}}>
      <div style={{ display: 'flex', gap: '10px', marginBottom: '10px' }}>
        <CSVLink 
          data={flattenRowData} 
          filename={`${patient.name}_${new Date().toISOString().split('T')[0]}.csv`}
          style={{ textDecoration: 'none', flex: 1 }}
        >
          <Tooltip title="테이블 차트의 데이터로만 내보냅니다" placement="top">
            <Button
              variant="contained" 
              color="primary" 
              style={{
                backgroundColor: '#2196f3',
                textTransform: 'none',
                width: '100%'
              }}
            >
              CSV 내보내기
            </Button>
          </Tooltip>
        </CSVLink>
        <Tooltip title="테이블 차트의 데이터로만 내보냅니다" placement="top">
          <Button
            variant="contained"
            color="primary"
            onClick={handleExcelDownload}
            style={{
              backgroundColor: '#2196f3',
              textTransform: 'none',
              flex: 1
            }}
          >
            Excel 내보내기
          </Button>
        </Tooltip>
      </div>
      <TableContainer
      component={Paper}
      style={{
        height: 'calc(100% - 45px)', // 버튼 높이(36px)와 마진(10px)을 고려한 계산
        overflowY: 'auto',
        flex: 1,
        position: 'relative',
        border: '1px solid #dde2eb',
        borderRadius: '4px',
        boxShadow: 'none',
      }}
      ref={containerRef}
      sx={tableStyles.tableContainer}
      >
        <Table stickyHeader size="small">
          <TableHead>
            <TableRow sx={{ '& th': {padding: '0px'}}}>
              <TableCell />
              <TableCell>날짜</TableCell>
              <TableCell>수축기 혈압</TableCell>
              <TableCell>이완기 혈압</TableCell>
              <TableCell>체중</TableCell>
              <TableCell>심박수</TableCell>
              <TableCell>실신</TableCell>
              <TableCell>소변 감소</TableCell>
              <TableCell>불편감</TableCell>
              <TableCell>부종</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rowData.map((row, index) => (
              <Row key={index} row={row} />
            ))}
          </TableBody>
        </Table>
        {loading && (
          <div style={{ 
            textAlign: 'center', 
            padding: '10px',
            position: 'sticky',  // loading 인디케이터를 고정
            bottom: 0,
            backgroundColor: 'white'
          }}>
            <CircularProgress />
          </div>
        )}
</TableContainer>
    </div>
  );
};

export default PatientGrid;
