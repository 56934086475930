import React from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage'; // 로그인 페이지 컴포넌트
import DashboardPage from './pages/DashboardPage'; // 대시보드 페이지 컴포넌트
import KakaoCallback from './components/KakaoCallback';
import { setNavigateFunction } from './components/Refresh';

// NavigateProvider 컴포넌트 생성
const NavigateProvider = ({ children }) => {
  const navigate = useNavigate();
  React.useEffect(() => {
    setNavigateFunction(navigate);
  }, [navigate]);
  
  return children;
};

const App = () => {
  return (
    <Router>
      <NavigateProvider>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/kakao" element={<KakaoCallback />} /> {/* 콜백 경로 */}
          <Route path="*" element={<LoginPage />} /> {/* 기본 경로를 로그인 페이지로 설정 */}
        </Routes>
      </NavigateProvider>
    </Router>
  );
};

export default App;
