// Refresh.js
import axios from 'axios';
import { loginRequest } from './UrlRequest';
import { useNavigate } from 'react-router-dom';

let alertShown = false; // 경고 메시지 중복 방지
let isRefreshing = false; // 중복 토큰 갱신 방지
let pendingRequests = []; // 대기 중인 요청을 저장하는 배열
let navigateFunction = null; // 전역 navigate 함수

export const setNavigateFunction = (navigate) => {
  navigateFunction = navigate;
};

export const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise((resolve) => {
      pendingRequests.push((token) => resolve(token));
    });
  }

  isRefreshing = true;

  try {
    const refreshEndpoint = '/api/v1/refresh';
    const tokenData = {
      accessToken: localStorage.getItem('accessToken'),
      refreshToken: localStorage.getItem('refreshToken'),
    };

    const response = await loginRequest(refreshEndpoint, tokenData);

    if (response && response.accessToken && response.refreshToken) {
      localStorage.setItem('accessToken', response.accessToken);
      localStorage.setItem('refreshToken', response.refreshToken);

      processPendingRequests({ 
        accessToken: response.accessToken, 
        refreshToken: response.refreshToken 
      });
      alertShown = false;
      return { 
        accessToken: response.accessToken, 
        refreshToken: response.refreshToken 
      };
    } else {
      if (!alertShown) {
        alert('토큰이 만료되었습니다. 재로그인 후 이용해주세요.');
        alertShown = true;
      }
      processPendingRequests(null);
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      if (navigateFunction) {
        navigateFunction('/login');
      }
      return null;
    }
  } catch (error) {
    processPendingRequests(null);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    if (navigateFunction) {
      navigateFunction('/login');
    }
    return null;
  } finally {
    isRefreshing = false;
  }
};

export const handleTokenExpiry = async (originalRequest) => {
  try {
    const tokens = await refreshToken();
    
    if (tokens && tokens.accessToken) {
      // 토큰 갱신 성공
      originalRequest.headers['Authorization'] = `Bearer ${tokens.accessToken}`;
      return axios(originalRequest);
    } else {
      // 토큰 갱신 실패
      throw new Error('토큰 갱신 실패');
    }
  } catch (error) {
    if (!alertShown) {
      alert('토큰이 만료되었습니다. 재로그인 후 이용해주세요.');
      alertShown = true;
    }
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    if (navigateFunction) {
      navigateFunction('/login');
    }
    throw error;
  }
};

const processPendingRequests = (newToken) => {
  pendingRequests.forEach((callback) => callback(newToken));
  pendingRequests = []; // 대기열 초기화
};
