import React, { useState, useEffect } from 'react';
import { getRequest } from '../UrlRequest';
import Chart from './Chart';
import { useLocation } from 'react-router-dom';
import { height } from '@mui/system';

const DataChart = ({
  patientId,
  dataType,
  endpoint,
  label,
  multipleLines,
  selectedDate,
  selectedPeriod,
  // 새로운 props 추가
  chartLabels: providedLabels,
  chartData: providedData,
  chartData2: providedData2,
  chartDummy: providedDummy,
}) => {
  const [chartLabels, setChartLabels] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartData2, setChartData2] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartDummy, setChartDummy] = useState([]);
  const [error, setError] = useState(null);
  const location = useLocation();
  const application = location.state?.application || 'hearton';

  const fetchData = async () => {
    setLoading(true);
    setError(null);

    try {
      const token = localStorage.getItem('accessToken');
      const response = await getRequest(
        application,
        `${endpoint}?on-date=${selectedDate}&period=${selectedPeriod}`,
        token
      );

      if (response) {
        setChartLabels(response.dates || []);
        setChartData(response.values || []);
        if (multipleLines) {
          setChartData2(response.values2 || []);
        }
        setChartDummy(response.is_dummy || []);
      } else {
        setError('데이터를 불러오는데 실패했습니다.');
      }
    } catch (error) {
      setError('데이터를 불러오는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (providedLabels && providedData) {
      setChartLabels(providedLabels);
      setChartData(providedData);
      if (providedData2) setChartData2(providedData2);
      if (providedDummy) setChartDummy(providedDummy);
      return;
    }

    fetchData();
  }, [application, patientId, selectedDate, selectedPeriod, providedLabels, providedData, providedData2, providedDummy]);

  if (loading) return <p>로딩 중...</p>;
  if (error) return <p>{error}</p>;

  if (!chartLabels.length || !chartData.length) {
    return <p>선택한 기간에 대한 데이터가 없습니다.</p>;
  }

  return (
    <div style={{ height: '90%', width: '100%' }}>
      <Chart
        labels={chartLabels}
        data={chartData}
        data2={multipleLines ? chartData2 : null}
        label={label}
        label2={multipleLines ? '이완기' : null}
        dummyData={chartDummy}
      />
    </div>
  );
};

export default DataChart;
