import React, { useState } from 'react';
import Sidebar from '../components/Sidebar';
import Pulse from '../components/rightpannel/Pulse';
import Weight from '../components/rightpannel/Weight';
import BloodPressure from '../components/rightpannel/BloodPressure';
import Push from '../components/rightpannel/Push';
import PatientInfo from '../components/rightpannel/PatientInfo';
import PatientGrid from '../components/rightpannel/PatientGrid';
import Tabs from '../components/rightpannel/Tabs';
import Diet from '../components/rightpannel/Diet';
//import Push from '../components/rightpannel/Push';
import ChatList from '../components/rightpannel/ChatList';
import SideTab from '../components/SideTab';

import './DashboardPage.css';

const DashboardPage = () => {
  const [selectedPatientId, setSelectedPatientId] = useState(null);
  const [selectedPatientInfo, setSelectedPatientInfo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(7);
  const [deviceStatusId, setDeviceStatusId] = useState(null);
  const [statsVisible, setStatsVisible] = useState(true);
  const [activeComponent, setActiveComponent] = useState(null);
  const [showCharts, setShowCharts] = useState(false);

  const handleRowClick = (patientId, onDate) => {
    setSelectedPatientId(patientId);
    setSelectedDate(onDate);
    setShowCharts(true);
  };

  const handlePeriodChange = (period) => {
    setSelectedPeriod(period);
  };

  const handleToggleChatList = () => {
    setActiveComponent(activeComponent === 'chat' ? null : 'chat');
    setStatsVisible(activeComponent === 'chat');
  };

  const handleToggleNotification = () => {
    setActiveComponent(activeComponent === 'notification' ? null : 'notification');
  };

  const handleTogglePush = () => {
    setActiveComponent(activeComponent === 'push' ? null : 'push');
  };

  const renderActiveComponent = () => {
    switch (activeComponent) {
      case 'chat':
        return (
          <ChatList 
            onVisibilityChange={(visible) => setStatsVisible(!visible)} 
            patientId={selectedPatientId} 
            patient={selectedPatientInfo}
          />
        );
      case 'notification':
      case 'push':
        return <Push onClose={() => setActiveComponent(null)} />;
      default:
        return null;
    }
  };

  return (
    <div className="dashboard-container">
      <div className="grid-container">
        <div className="dashboard">
          <div className="sidebar">
            <Sidebar
              setSelectedPatientId={setSelectedPatientId}
              setSelectedPatientInfo={setSelectedPatientInfo}
              setSelectedDeviceStatusId={setDeviceStatusId}
            />
          </div>

          <div className="patientinfo">
            <PatientInfo
              patient={selectedPatientInfo} 
              onPeriodChange={handlePeriodChange} 
            />
          </div>
{/*
          <div className="push">
            <Push />
          </div>
*/}
          {selectedPatientId && (
            <>
              <div className="patientgrid">
                <PatientGrid 
                  patientId={selectedPatientId}
                  patient={selectedPatientInfo} 
                  setSelectedDate={setSelectedDate}
                  setSelectedPeriod={setSelectedPeriod}
                  onDataClick={() => setShowCharts(true)}
                />
              </div>
              
              <div className="devicestatus">
                <Tabs patientId={selectedPatientId} selectedDate={selectedDate} deviceStatusId={deviceStatusId} />
              </div>
            </>
          )}
        </div>
      </div>

      {statsVisible && (
        <div className="side-tab-container">
          <SideTab
            patientId={selectedPatientId}
            selectedDate={selectedDate}
            selectedPeriod={selectedPeriod}
            patient={selectedPatientInfo}
            onVisibilityChange={(visible) => setStatsVisible(visible)}
            showCharts={showCharts}
          />
        </div>
      )}
      {renderActiveComponent()}
    </div>
  );
};

export default DashboardPage;
